
            @import 'src/styles/mixins.scss';
        
.whatToLookIcon{
    svg{
       @include md{
        width: 178px;
       }
        width: 150px;
       
    }
}
@media only screen and (max-width: 1100px) {
    .whatToLookIcon{
       
        svg{
           width:160px;
        }
    }
  }
@media only screen and (max-width: 1000px) {
    .whatToLookIcon{
       
        svg{
           width:140px;
        }
    }
  }
@media only screen and (max-width: 900px) {
    .whatToLookIcon{
     
        svg{
           width:120px;
        }
    }
  }
@media only screen and (max-width: 765px) {
    .whatToLookIcon{
     
        svg{
           width:150px;
        }
    }
  }


